import styled from '@emotion/styled'
import { Flex } from '@rebass/grid/emotion'

import { MediaQueries } from '../../utils/styles'

const BlogGridContainer = styled(Flex)`
  margin: 0 auto;
  max-width: 1000px;
  @media (max-width: ${MediaQueries.MOBILE}) {
    padding: '0 12px';
  }
`
export default BlogGridContainer
