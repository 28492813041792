import { Colors } from './Color'
import { Gutter } from './Layout'

// Granular Font Styles
export const FontSizes = {
  BASE: '16px',
  LARGE: '20px',
}

export const FontFamily = {
  PRIMARY: 'IBM Plex Sans',
  SECONDARY: 'Gotham',
}

// Variables
const paragraphSpacing = '20px'
const baseLineHeight = 1.8
const baseLetterSpacing = '0.2px'

const paragraphNormalStyle = {
  fontFamily: FontFamily.PRIMARY,
  color: Colors.BLACK,
  fontSize: FontSizes.BASE,
  fontWeight: 400,
  letterSpacing: baseLetterSpacing,
  lineHeight: baseLineHeight,
}

const paragraphLargeStyle = {
  color: Colors.BLACK,
  fontSize: FontSizes.LARGE,
  fontWeight: 400,
  letterSpacing: baseLetterSpacing,
  lineHeight: '30px',
}

// TODO edit list style
const fontStyleList = {
  marginBottom: paragraphSpacing,
  paddingLeft: Gutter.HALF, // Sets the indentation space between bullet/number and text
}

// TODO edit Paragraph style
const fontStyleParagraph = {
  marginBottom: paragraphSpacing,
}

export const FontStyles = {
  PARAGRAPH_NORMAL: paragraphNormalStyle,
  PARAGRAPH_LARGE: paragraphLargeStyle,
  LIST: fontStyleList,
  PARAGRAPH_SPACING: fontStyleParagraph,
}
