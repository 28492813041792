import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Flex } from '@rebass/grid/emotion'

import { Grid, Gutter, MediaQueries } from '../../utils/styles'

// GridContainer is built off of rebass/grid Flex component
// It can accept all props that the Flex component accepts
// See rebass/grid docs for more info

// GridContainer is a the wrapper around ContentContainer

const GridContainer = styled(Flex)`
  margin: 0 auto;
  max-width: ${props => (props.fullWidth ? '100%' : Grid.MAX_WIDTH)};
  padding: ${props => (props.noGutter ? 0 : `0 ${Gutter.HALF}`)};
  @media (max-width: ${MediaQueries.MOBILE}) {
    padding: ${props => (props.noGutter ? 0 : '0 12px')};
  }
`
export default GridContainer


GridContainer.propTypes = {
  fullWidth: PropTypes.bool,
  noGutter: PropTypes.bool,
}
