import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'

import { Gutter, MediaQueries } from '../../utils/styles'

// ContentContainer is built off of rebass/grid Box component
// It can accept all props that the Box component accepts
// See rebass/grid docs for more info

// ContentContainer is the child of the GridContainer

const ContentContainer = styled(Box)`
  padding: ${props => (props.noGutter ? 0 : `${Gutter.HALF}`)};
  @media (max-width: ${MediaQueries.MOBILE}) {
    padding: ${props => (props.noGutter ? 0 : '12px')};
  }
`
export default ContentContainer

ContentContainer.propTypes = {
  noGutter: PropTypes.bool,
}
