import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { lighten, transparentize } from 'polished'

import { Colors, Gutter, Transitions } from '../../utils/styles'

const StyledLink = styled(Link)(
  {
    borderRadius: 4,
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    padding: `${Gutter.HALF} ${Gutter.THREE_QUARTER}`,
    textDecoration: 'none',
    transition: Transitions.NORMAL,
    whiteSpace: 'nowrap',
  },
  props => ({
    color: props.secondary ? props.buttonColor : props.textColor,
    backgroundColor: props.secondary ? 'transparent' : props.buttonColor,
    boxShadow: props.secondary ? 'none' : `0 2px 16px 0 ${props.buttonShadowColor}`,
    border: props.secondary ? `1px solid ${props.buttonColor}` : 'none',
    ':hover': {
      color: props.textColor,
      backgroundColor: props.secondary ? transparentize(0.7, props.buttonColor)
        : lighten(0.1, props.buttonColor),
      boxShadow: '0 8px 16px 0 rgba(21,73,159,0.48)',
      transform: 'translateY(-2px)',
    },
    ':active': {
      transform: 'translateY(0)',
    },
  })
)

const ButtonLink = props => (
  <StyledLink
    to={props.to}
    buttonColor={props.buttonColor}
    buttonShadowColor={props.buttonShadowColor}
    textColor={props.textColor}
    id={props.id}
    secondary={props.secondary}
    style={props.style}
  >
    {props.children}
  </StyledLink>
)

export default ButtonLink

ButtonLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttonColor: PropTypes.string,
  buttonShadowColor: PropTypes.string,
  textColor: PropTypes.string,
  id: PropTypes.string,
  secondary: PropTypes.bool,
  style: PropTypes.object,
}

ButtonLink.defaultProps = {
  to: '/',
  buttonColor: Colors.PRIMARY,
  buttonShadowColor: 'rgba(21,73,159,0.32)',
  textColor: Colors.WHITE,
  id: 'button-link',
  secondary: false,
  style: {},
}
