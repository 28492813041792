import { transparentize } from 'polished'

export const Colors = {
  // Theme Colors
  PRIMARY: '#0656D9',
  SECONDARY: '#0EA8F3',
  TERTIARY: '#FA8969',

  // Utility Colors
  BLACK: '#0E1E38',
  WHITE: '#FFFFFF',
  OFF_WHITE: '#FAFDFF',
  FOOTER_BLACK: '#051345',

  // Grays
  LIGHT_GRAY: '#F8FAFC',
  LIGHT_BLUE_GRAY: '#F0F8FF',
  LIGHT_BLUE_GRAY_2: '#F1F5FA',
  MEDIUM_BLUE_GRAY: '#97B4DB',

  // TODO System Colors (red, yellow, green, orange)
}

export const AlphaColors = {
  // Black Opacity Variants
  BLACK_90: transparentize(0.1, Colors.BLACK),
  BLACK_80: transparentize(0.2, Colors.BLACK),
  BLACK_70: transparentize(0.3, Colors.BLACK),
  BLACK_60: transparentize(0.4, Colors.BLACK),
  BLACK_50: transparentize(0.5, Colors.BLACK),
  BLACK_40: transparentize(0.6, Colors.BLACK),
  BLACK_30: transparentize(0.7, Colors.BLACK),
  BLACK_20: transparentize(0.8, Colors.BLACK),
  BLACK_15: transparentize(0.85, Colors.BLACK),
  BLACK_12: transparentize(0.88, Colors.BLACK),
  BLACK_10: transparentize(0.9, Colors.BLACK),
  BLACK_8: transparentize(0.92, Colors.BLACK),
  BLACK_7: transparentize(0.93, Colors.BLACK),
  BLACK_5: transparentize(0.95, Colors.BLACK),

  // White Opacity Variants
  WHITE_90: transparentize(0.1, Colors.WHITE),
  WHITE_80: transparentize(0.2, Colors.WHITE),
  WHITE_70: transparentize(0.3, Colors.WHITE),
  WHITE_60: transparentize(0.4, Colors.WHITE),
  WHITE_50: transparentize(0.5, Colors.WHITE),
  WHITE_40: transparentize(0.6, Colors.WHITE),
  WHITE_30: transparentize(0.7, Colors.WHITE),
  WHITE_20: transparentize(0.8, Colors.WHITE),
  WHITE_15: transparentize(0.85, Colors.WHITE),
  WHITE_12: transparentize(0.88, Colors.WHITE),
  WHITE_10: transparentize(0.9, Colors.WHITE),
  WHITE_8: transparentize(0.92, Colors.WHITE),
  WHITE_7: transparentize(0.93, Colors.WHITE),
  WHITE_5: transparentize(0.95, Colors.WHITE),

  // PrimaryColor Opacity Variants
  PRIMARY_90: transparentize(0.1, Colors.PRIMARY),
  PRIMARY_80: transparentize(0.2, Colors.PRIMARY),
  PRIMARY_70: transparentize(0.3, Colors.PRIMARY),
  PRIMARY_60: transparentize(0.4, Colors.PRIMARY),
  PRIMARY_50: transparentize(0.5, Colors.PRIMARY),
  PRIMARY_40: transparentize(0.6, Colors.PRIMARY),
  PRIMARY_30: transparentize(0.7, Colors.PRIMARY),
  PRIMARY_20: transparentize(0.8, Colors.PRIMARY),
  PRIMARY_15: transparentize(0.85, Colors.PRIMARY),
  PRIMARY_12: transparentize(0.88, Colors.PRIMARY),
  PRIMARY_10: transparentize(0.9, Colors.PRIMARY),
  PRIMARY_8: transparentize(0.92, Colors.PRIMARY),
  PRIMARY_7: transparentize(0.93, Colors.PRIMARY),
  PRIMARY_5: transparentize(0.95, Colors.PRIMARY),

  // SecondaryColor Opacity Variants
  SECONDARY_90: transparentize(0.1, Colors.SECONDARY),
  SECONDARY_80: transparentize(0.2, Colors.SECONDARY),
  SECONDARY_70: transparentize(0.3, Colors.SECONDARY),
  SECONDARY_60: transparentize(0.4, Colors.SECONDARY),
  SECONDARY_50: transparentize(0.5, Colors.SECONDARY),
  SECONDARY_40: transparentize(0.6, Colors.SECONDARY),
  SECONDARY_30: transparentize(0.7, Colors.SECONDARY),
  SECONDARY_20: transparentize(0.8, Colors.SECONDARY),
  SECONDARY_15: transparentize(0.85, Colors.SECONDARY),
  SECONDARY_12: transparentize(0.88, Colors.SECONDARY),
  SECONDARY_10: transparentize(0.9, Colors.SECONDARY),
  SECONDARY_8: transparentize(0.92, Colors.SECONDARY),
  SECONDARY_7: transparentize(0.93, Colors.SECONDARY),
  SECONDARY_5: transparentize(0.95, Colors.SECONDARY),
}

export const Shadows = {
  NORMAL: `0 6px 20px ${AlphaColors.PRIMARY_15}`,
}
