//  Need to rework this. This currently doesn't work

import { keyframes } from '@emotion/core'

const fadeInMoveUpAnimation = keyframes`
    0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Animations = {
  FADE_IN_MOVE_UP: fadeInMoveUpAnimation,
}

export const Transitions = {
  NORMAL: '300ms ease',
}
