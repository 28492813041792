import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Colors, Gutter } from '../../utils/styles'

// SectionContainers should be the highest level GridContainer
// GridContainer and ContentContainer components should live within
// the Section component

const SectionContainer = styled.div(props => ({
  width: '100%',
  background: `url(${props.backgroundImage}), ${props.backgroundColor}`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: 'center',
  WebkitBackgroundSize: 'cover',
  MozBackgroundSize: 'cover',
  OBackgroundSize: 'cover',
  backgroundSize: 'cover',
  position: 'relative',
  padding: `${props.verticalPadding} 0`,
  '@media (max-width: 520px)': {
    padding: `${Gutter.FULL} 0`,
  },
}))

const Section = props => (
  <SectionContainer
    backgroundImage={props.backgroundImage}
    backgroundColor={props.backgroundColor}
    verticalPadding={props.verticalPadding}
    id={props.id}
  >
    {props.children}
  </SectionContainer>
)

export default Section

Section.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  verticalPadding: PropTypes.string,
}

Section.defaultProps = {
  backgroundColor: Colors.LIGHT_BLUE_GRAY,
  backgroundImage: '',
  id: null,
  verticalPadding: Gutter.TRIPLE,
}
