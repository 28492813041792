export const Heights = {
  PRIMARY_NAV_BAR: '82px',
  HIRING_BANNER: '60px',
}

// Currently not using
// export const HeightsCombined = {
//   NAV_BANNER: `calc(${Heights.PRIMARY_NAV_BAR} + ${Heights.HIRING_BANNER})`,
// }

export const Grid = {
  // MAX_WIDTH: '1472px',
  // MAX_WIDTH: '1520px', This was the old max width of the grid. Increased after August 2019
  MAX_WIDTH: '1680px',
}

export const Gutter = {
  QUADRUPLE: '128px',
  TRIPLE: '96px',
  DOUBLE: '64px',
  FULL: '32px',
  THREE_QUARTER: '24px',
  HALF: '16px',
  QUARTER: '8px',
}

export const ZIndexes = {
  PRIMARY_NAV_BAR: 1000,
  NAV_BUTTON: 10,
  NAV_MOBILE_PANEL: 9,
  NAV_MOBILE_MENU: 1001,
}

export const MediaQueries = {
  MOBILE: '520px',
  TABLET: '900px',
  DESKTOP: '1100px',
  NAVBAR: '1120px',
}

const breakpoints = [MediaQueries.MOBILE, MediaQueries.TABLET, MediaQueries.DESKTOP]

// 0 = MOBILE, 1 = TABLET, 2 = DESKTOP
export const mediaQueryUtility = breakpoints.map(bp => `@media (max-width: ${bp})`)
